define(['app', 'cookie'], function (app, cookie) {

  const toastModal = function () {
    let self = this;
    self.app = app;

    const _config = {
      toastDeclineButton: '[data-js-element=toast-no-thanks-button]',
      toastLinkButton: '[data-js-element=toast-link-button]',
      cookie: '[data-js-element=toast-modal-element]',
      cookieName: 'toastCookie',
      cookieGroup: cookie.group.REQUIRED
    };

    const _init = (element) => {
      self.element = element;
      self.toastDeclineButton = document.querySelector(self.config.toastDeclineButton);
      self.toastLinkButton = document.querySelector(self.config.toastLinkButton);
      self.cookieElement = document.querySelector(self.config.cookie);
      self.checkCookieExists();
      return self;
    };

    //create the cookie
    const _cookieButtonHandleDecline = () => {
      cookie.set(self.config.cookieName, self.config.cookieGroup, self.config.cookieName, { expires: 365, path: '/' });
      app.publish('tracking/record', 'toast Modal', 'Clicked', 'Accept Cookie Button');
      self.cookieElement.style.display = 'none';
      self.cookieElement.classList.remove("show");
      self.cookieElement.classList.add("hidden");
    }

    // if cookie exists hide element, if not show it and wait for click event
    const _checkCookieExists = () => {
      if (cookie.get(self.config.cookieName)) {
        self.cookieElement.style.display = 'none';
      } else {
        app.publish('tracking/record', 'toast Modal', 'Shown', 'toast Modal Message');
        self.toastDeclineButton.addEventListener('click', self.cookieButtonHandleDecline);
        self.toastLinkButton.addEventListener('click', self.cookieButtonHandleDecline);
        self.cookieElement.classList.remove("hidden");
        self.cookieElement.classList.add("show");
      }
    }

    self.config = _config;
    self.init = _init;
    self.checkCookieExists = _checkCookieExists;
    self.cookieButtonHandleDecline = _cookieButtonHandleDecline;
  };

  return toastModal;
});
